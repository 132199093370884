import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  confirmPasswordReset,
  signOut,
  verifyPasswordResetCode,
} from "firebase/auth";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { auth } from "../../auth/config";
import { Routes } from "../../helpers/router";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/chackra-ui-button";
import * as Sentry from "@sentry/react";

type FormValues = {
  password: string;
  passwordConfirmation: string;
};

const validationSchema = (t: TFunction) =>
  Yup.object({
    password: Yup.string()
      .min(6, t("usermgmt.verifyResetPasswordForm.passwordMinLength"))
      .required(t("usermgmt.verifyResetPasswordForm.fieldRequired")),
    passwordConfirmation: Yup.string()
      .min(6, t("usermgmt.verifyResetPasswordForm.passwordMinLength"))
      .required(t("usermgmt.verifyResetPasswordForm.fieldRequired"))
      .oneOf(
        [Yup.ref("password")],
        t("usermgmt.verifyResetPasswordForm.passwordsMismatch")
      ),
  });

type Props = {
  oobCode: string;
};

export const VerifyResetPasswordForm = ({ oobCode }: Props) => {
  const { t } = useTranslation();
  const [alertMessage, setAlertMessage] = useState<
    "completed" | "expired" | "invalid" | undefined
  >(undefined);

  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema(t)),
  });

  const onSubmit = handleSubmit(
    async (values) => {
      try {
        await verifyPasswordResetCode(auth, oobCode);
        await confirmPasswordReset(auth, oobCode, values.password);
        await signOut(auth);
        setAlertMessage("completed");
      } catch (error: any) {
        if (error.code === "auth/expired-action-code") {
          setAlertMessage("expired");
        } else if (error.code === "auth/weak-password") {
          setError("password", {
            message: t("usermgmt.verifyResetPasswordForm.passwordMinLength"),
          });
        } else if (error.code === "auth/invalid-action-code") {
          setAlertMessage("invalid");
        }

        Sentry.captureException(error);
      }
    },
    (errors, event) => {
      Sentry.captureException(
        new Error("Unable to submit verify reset password form"),
        {
          extra: {
            event,
            errors,
          },
        }
      );
    }
  );

  return (
    <div>
      {alertMessage === "expired" ? (
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          py={8}
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            {t("usermgmt.verifyResetPasswordForm.expiredLinkTitle")}
          </AlertTitle>
          <AlertDescription maxWidth="sm" mb={4}>
            {t("usermgmt.verifyResetPasswordForm.expiredLinkDescription")}
          </AlertDescription>
          <Box>
            <Button as={Link} to={Routes.ResetPassword()}>
              {t("usermgmt.verifyResetPasswordForm.requestNewLink")}
            </Button>
          </Box>
        </Alert>
      ) : alertMessage === "invalid" ? (
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          py={8}
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            {t("usermgmt.verifyResetPasswordForm.invalidLink")}
          </AlertTitle>
          <AlertDescription maxWidth="sm" mb={4}>
            {t("usermgmt.verifyResetPasswordForm.invalidLinkDescription")}
          </AlertDescription>
          <Box>
            <Button as={Link} to={Routes.ResetPassword()}>
              {t("usermgmt.verifyResetPasswordForm.requestNewLink")}
            </Button>
          </Box>
        </Alert>
      ) : alertMessage === "completed" ? (
        <Alert
          status="success"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          py={8}
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            {t("usermgmt.verifyResetPasswordForm.passwordUpdatedTitle")}
          </AlertTitle>
          <AlertDescription maxWidth="sm" mb={4}>
            {t("usermgmt.verifyResetPasswordForm.passwordUpdatedDescription")}
          </AlertDescription>
          <Box>
            <Button as={Link} to={Routes.Login()}>
              {t("usermgmt.verifyResetPasswordForm.login")}
            </Button>
          </Box>
        </Alert>
      ) : (
        <div>
          <Heading size="lg" textAlign="center" mb={12}>
            {t("usermgmt.verifyResetPasswordForm.changePassword")}
          </Heading>
          <form onSubmit={onSubmit}>
            <FormControl isInvalid={!!errors.password}>
              <FormLabel htmlFor="email">
                {t("usermgmt.verifyResetPasswordForm.newPassword")}
              </FormLabel>
              <Input
                id="password"
                size="lg"
                type="password"
                border="1px"
                borderColor="gray.300"
                errorBorderColor="red.500"
                borderRadius={4}
                {...register("password")}
              />
              {!!errors.password && errors.password?.message ? (
                <FormErrorMessage>{errors.password.message}</FormErrorMessage>
              ) : null}
            </FormControl>
            <FormControl
              mt={4}
              mb={8}
              isInvalid={!!errors.passwordConfirmation}
            >
              <FormLabel htmlFor="email">
                {t("usermgmt.verifyResetPasswordForm.confirmPassword")}
              </FormLabel>
              <Input
                id="passwordConfirmation"
                size="lg"
                type="password"
                border="1px"
                borderColor="gray.300"
                errorBorderColor="red.500"
                borderRadius={4}
                {...register("passwordConfirmation")}
              />
              {!!errors.passwordConfirmation &&
              errors.passwordConfirmation?.message ? (
                <FormErrorMessage>
                  {errors.passwordConfirmation.message}
                </FormErrorMessage>
              ) : null}
            </FormControl>
            <Button
              type="submit"
              colorScheme="blue"
              size="lg"
              w="full"
              isLoading={isSubmitting}
              borderRadius={4}
            >
              {t("usermgmt.verifyResetPasswordForm.updatePassword")}
            </Button>
          </form>
        </div>
      )}
    </div>
  );
};
