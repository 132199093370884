import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { signInWithEmailAndPassword } from "firebase/auth";
import { TFunction } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import { auth } from "../../auth/config";
import { Routes } from "../../helpers/router";
import { Button } from "../../components/chackra-ui-button";
import * as Sentry from "@sentry/react";
type FormValues = {
  email: string;
  password: string;
};

const validationSchema = (t: TFunction) =>
  Yup.object({
    email: Yup.string().required(t("login.email.emailRequired")),
    password: Yup.string().required(t("login.email.passwordRequired")),
  });

export const LoginEmailForm = () => {
  const toast = useToast();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema(t)),
  });

  const onSubmit = handleSubmit(
    async ({ email, password }) => {
      try {
        await signInWithEmailAndPassword(auth, email, password);
      } catch (error: any) {
        toast({
          title: t("login.errors.invalidCredentials"),
          description: t("login.errors.checkCredentials"),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        Sentry.captureException(error);
      }
    },
    (errors, event) => {
      Sentry.captureException(new Error("Unable to submit login form"), {
        extra: {
          event,
          errors,
        },
      });
    }
  );

  return (
    <form onSubmit={onSubmit}>
      <FormControl isInvalid={!!errors.email}>
        <FormLabel htmlFor="email">{t("login.email.emailLabel")}</FormLabel>
        <Input
          id="email"
          size="lg"
          type="email"
          border="1px"
          borderColor="gray.300"
          errorBorderColor="red.500"
          borderRadius={4}
          bg="white"
          {...register("email")}
        />
        {!!errors.email && errors.email?.message ? (
          <FormErrorMessage>{errors.email.message}</FormErrorMessage>
        ) : null}
      </FormControl>
      <FormControl mt={4} isInvalid={!!errors.password}>
        <FormLabel htmlFor="password">
          {t("login.email.passwordLabel")}
        </FormLabel>
        <Input
          id="password"
          size="lg"
          type="password"
          border="1px"
          borderColor="gray.300"
          errorBorderColor="red.500"
          borderRadius={4}
          bg="white"
          {...register("password")}
        />
        {!!errors.password && errors.password?.message ? (
          <FormErrorMessage>{errors.password.message}</FormErrorMessage>
        ) : null}
      </FormControl>
      <Box mb={8} mt={3} display="flex" justifyContent="flex-start">
        <Link as={RouterLink} to={Routes.ResetPassword()} color="blue.500">
          {t("login.email.forgotPassword")}
        </Link>
      </Box>
      <Button
        type="submit"
        colorScheme="blue"
        size="lg"
        w="full"
        isLoading={isSubmitting}
        borderRadius={4}
      >
        {t("login.email.loginButton")}
      </Button>
    </form>
  );
};
