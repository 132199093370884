import { Box, Divider, HStack, Text, useToast, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { isValidPhoneNumber } from "libphonenumber-js";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { countries } from "../../../helpers/countries";
import { Routes } from "../../../helpers/router";

import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { ProfileForm } from "./profile-form";
import { TermsForm } from "./terms-form";

import { useCreateProfileMutation } from "../../../graphql/generated/user-hooks";
import { mapProfileSex } from "./profile.mappers";
import { getTenantConfig } from "../../../helpers/get-tenant-config";
import { Button } from "../../../components/chackra-ui-button";
import * as Sentry from "@sentry/react";

const validationSchema = (t: TFunction) =>
  Yup.object({
    firstName: Yup.string().required(t("personalDetails.fieldRequired")).trim(),
    lastName: Yup.string().required(t("personalDetails.fieldRequired")).trim(),
    identityNumber: Yup.string()
      .uppercase()
      .trim()
      .when("dateOfBirth", {
        is: (value: string) => dayjs().diff(value, "years") >= 18,
        then: (schema) => schema.required(t("personalDetails.fieldRequired")),
        otherwise: (schema) => schema.notRequired(),
      }),
    dateOfBirth: Yup.string()
      .test("valid", "Esta fecha no es válida", (value) =>
        dayjs(value, "YYYY-MM-DD", true).isValid()
      )
      .required(t("personalDetails.fieldRequired")),
    country: Yup.string()
      .required(t("personalDetails.fieldRequired"))
      .oneOf(countries.map((country) => country.code)),
    zipCode: Yup.string().required(t("personalDetails.fieldRequired")).trim(),
    sex: Yup.string()
      .oneOf(["MALE", "FEMALE", "NOT_SPECIFIED"])
      .required(t("personalDetails.fieldRequired")),
    phoneNumber: Yup.string()
      .trim()
      .required(t("personalDetails.fieldRequired"))
      .test("valid", t("personalDetails.invalidPhoneNumber"), (value) =>
        value ? isValidPhoneNumber(value) : false
      ),
    city: Yup.string().required(t("personalDetails.fieldRequired")).trim(),
    address: Yup.string().required(t("personalDetails.fieldRequired")).trim(),
    // supervisorFirstName: Yup.string()
    //   .trim()
    //   .when("dateOfBirth", {
    //     is: (value: string) => dayjs().diff(value, "years") < 18,
    //     then: (schema) =>
    //       schema.required(t("personalDetails.form.fieldRequired")),
    //   }),
    // supervisorLastName: Yup.string()
    //   .trim()
    //   .when("dateOfBirth", {
    //     is: (value: string) => dayjs().diff(value, "years") < 18,
    //     then: (schema) =>
    //       schema.required(t("personalDetails.form.fieldRequired")),
    //   }),
    // supervisorPhoneNumber: Yup.string()
    //   .trim()
    //   .when("dateOfBirth", {
    //     is: (value: string) => dayjs().diff(value, "years") < 18,
    //     then: (schema) =>
    //       schema
    //         .test(
    //           "valid",
    //           t("personalDetails.form.invalidPhoneNumber"),
    //           (value) => (value ? isValidPhoneNumber(value) : false)
    //         )
    //         .required(t("personalDetails.form.fieldRequired")),
    //   }),
    // supervisorEmail: Yup.string()
    //   .trim()
    //   .when("dateOfBirth", {
    //     is: (value: string) => dayjs().diff(value, "years") < 18,
    //     then: (schema) =>
    //       schema.required(t("personalDetails.form.fieldRequired")),
    //   }),
    healthTerms: Yup.boolean()
      .required()
      .oneOf([true], t("personalDetails.mustAcceptTerms")),
    underageTerms: Yup.boolean().when("dateOfBirth", {
      is: (value: string) => dayjs().diff(value, "years") >= 18,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema
          .required(t("personalDetails.mustAcceptTerms"))
          .oneOf([true], t("personalDetails.mustAcceptTerms")),
    }),
  });

export type PersonalDetailsFormValues = {
  firstName: string;
  lastName: string;
  identityNumber?: string;
  dateOfBirth: string;
  country: string;
  zipCode: string;
  sex: "MALE" | "FEMALE" | "NOT_SPECIFIED";
  phoneNumber: string;
  city: string;
  address: string;
  // supervisorFirstName?: string;
  // supervisorLastName?: string;
  // supervisorPhoneNumber?: string;
  // supervisorEmail?: string;
  healthTerms: boolean;
  underageTerms?: boolean;
};

export const OnboardingPersonalDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // const planId = searchParams.get("plan_id");
  const toast = useToast();
  const methods = useForm<PersonalDetailsFormValues>({
    resolver: yupResolver(validationSchema(t)),
    reValidateMode: "onChange",
    defaultValues: {
      country: "ES",
    },
  });

  const [createProfile] = useCreateProfileMutation({
    context: {
      endpoint: "fitnext",
    },
    onError: (error) => {
      toast({
        title: error.message,
        status: "error",
        isClosable: true,
      });
      Sentry.captureException(error);
    },
    onCompleted: (data) => {
      if (data?.profile.id) {
        navigate({
          pathname: Routes.Dashboard(),
          search: searchParams.toString(),
        });
      }
    },
  });

  const onSubmit = methods.handleSubmit(
    async (values) => {
      try {
        await createProfile({
          variables: {
            input: {
              country: values.country,
              zipCode: values.zipCode,
              firstName: values.firstName,
              lastName: values.lastName,
              dateOfBirth: values.dateOfBirth,
              phoneNumber: values.phoneNumber,
              sex: mapProfileSex(values.sex),
              city: values.city,
              address: values.address,
              identityNumber: values.identityNumber,
              tenantId: getTenantConfig().id,
            },
          },
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    (errors, event) => {
      Sentry.captureException(
        new Error("Unable to submit personal details form"),
        {
          extra: {
            event,
            errors,
          },
        }
      );
    }
  );

  const dateOfBirth = methods.watch("dateOfBirth");

  const isUnderage =
    dayjs(dateOfBirth, "YYYY-MM-DD", true).isValid() &&
    dayjs().diff(dateOfBirth, "years") < 18;

  // TODO: Bring back when plan are enabled
  // if (!planId) {
  //   return <Navigate to={Router.Onboarding.Plan} replace />;
  // }

  // if (data?.profiles && data.profiles.length > 0) {
  //   return <Navigate to={Router.Dashboard} replace />;
  // }

  return (
    <Box display="flex" justifyContent="center" py={8}>
      <Box w="full" maxW="container.md" px={4}>
        <VStack spacing={8} w="full">
          <HStack justify="space-between" w="full">
            <VStack align="center" w="full">
              {/* <Text fontSize="sm" textAlign="center">
                PASO <strong>2</strong> DE <strong>3</strong>
              </Text> */}
              <Text fontSize="3xl" fontWeight={700} textAlign="center">
                {t("personalDetails.title")}
              </Text>
              {/* {data?.profiles && data.profiles.length > 0 ? (
                <ProfileSelect
                  profiles={data.profiles}
                  onProfileSelect={(id) => {
                    searchParams.append("profile_id", id);
                    navigate({
                      pathname: Router.Onboarding.Card,
                      search: searchParams.toString(),
                    });
                  }}
                />
              ) : null} */}
            </VStack>
          </HStack>
          <FormProvider {...methods}>
            <form style={{ width: "100%" }} onSubmit={onSubmit}>
              <ProfileForm />
              {/* {isUnderage && (
                <>
                  <Divider my={6} />
                  <Text fontSize="xl" fontWeight={700} mb={8}>
                    Datos del tutor legal
                  </Text>
                  <SupervisorForm />
                </>
              )} */}
              <Divider my={6} />
              <TermsForm showUnderageTerms={isUnderage} />
              <Box display="flex" justifyContent="center" mt={8}>
                <Button
                  isLoading={methods.formState.isSubmitting}
                  type="submit"
                  size="lg"
                  py="8"
                  minW={{ base: "full", md: "96" }}
                  colorScheme="blue"
                >
                  {t("personalDetails.nextButton")}
                </Button>
              </Box>
            </form>
          </FormProvider>
        </VStack>
      </Box>
    </Box>
  );
};
