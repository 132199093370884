import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type AccessLog = {
  createdAt: Scalars['DateTime']['output'];
  direction: AccessLogDirection;
  id: Scalars['ID']['output'];
  reason: AccessLogReason;
  status: AccessLogStatus;
};

export enum AccessLogDirection {
  In = 'IN',
  Out = 'OUT'
}

export enum AccessLogReason {
  Admin = 'ADMIN',
  Granted = 'GRANTED',
  NotFound = 'NOT_FOUND',
  PendingPayments = 'PENDING_PAYMENTS',
  Reused = 'REUSED',
  Subscription = 'SUBSCRIPTION',
  Timetable = 'TIMETABLE',
  TokenExpired = 'TOKEN_EXPIRED'
}

export enum AccessLogStatus {
  Ko = 'KO',
  Ok = 'OK'
}

export type Activity = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AppMetadata = {
  androidStoreUrl: Scalars['String']['output'];
  iosStoreUrl: Scalars['String']['output'];
  minVersion: Scalars['String']['output'];
};

export type Booking = {
  classSchedule: ClassSchedule;
  classScheduleId: Scalars['ID']['output'];
  datetime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  status: BookingStatus;
};

export enum BookingStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  NoShow = 'NO_SHOW',
  Show = 'SHOW',
  Waitlist = 'WAITLIST'
}

export type ClassSchedule = {
  activity: Activity;
  activityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  room: Room;
  roomId: Scalars['ID']['output'];
};

export type ClassSession = {
  activity: Activity;
  activityId: Scalars['ID']['output'];
  classScheduleId: Scalars['ID']['output'];
  endDateTime: Scalars['DateTime']['output'];
  room: Room;
  roomId: Scalars['ID']['output'];
  startDateTime: Scalars['DateTime']['output'];
  status: ClassSessionStatus;
};

export enum ClassSessionStatus {
  Available = 'AVAILABLE',
  Disabled = 'DISABLED',
  Full = 'FULL',
  Waitlist = 'WAITLIST'
}

export type CreateProfileInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  dateOfBirth: Scalars['DateTime']['input'];
  firstName: Scalars['String']['input'];
  identityNumber?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  sex: ProfileSex;
  tenantId: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Mutation = {
  bookClassSession?: Maybe<Scalars['Boolean']['output']>;
  cancelClassSessionBooking?: Maybe<Scalars['Boolean']['output']>;
  createProfile: Profile;
  createUser: Scalars['String']['output'];
  profileLogin: Scalars['String']['output'];
  resetPassword: Scalars['Boolean']['output'];
  sendResetPassword?: Maybe<Scalars['Boolean']['output']>;
  stripe_cancelSubscription: Stripe_Subscription;
  stripe_reactivateSubscription: Stripe_Subscription;
};


export type MutationBookClassSessionArgs = {
  classScheduleId: Scalars['ID']['input'];
  date: Scalars['String']['input'];
};


export type MutationCancelClassSessionBookingArgs = {
  bookingId: Scalars['ID']['input'];
};


export type MutationCreateProfileArgs = {
  input: CreateProfileInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationProfileLoginArgs = {
  id: Scalars['ID']['input'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationSendResetPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationStripe_CancelSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationStripe_ReactivateSubscriptionArgs = {
  id: Scalars['ID']['input'];
};

export type PageInfo = {
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type Profile = {
  address: Scalars['String']['output'];
  avatarUrl?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  dateOfBirth: Scalars['DateTime']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identityNumber?: Maybe<Scalars['String']['output']>;
  identityVerified: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  sex: ProfileSex;
  tenant: Tenant;
  tenantId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export enum ProfileSex {
  Female = 'FEMALE',
  Male = 'MALE',
  NotSpecified = 'NOT_SPECIFIED'
}

export type Profiles = {
  edges: Array<ProfilesEdge>;
  pageInfo: PageInfo;
};

export type ProfilesEdge = {
  cursor: Scalars['String']['output'];
  node: Profile;
};

export type ProfilesFilterInput = {
  tenantId?: InputMaybe<StringFilterInput>;
};

export type Query = {
  accessCredential: Scalars['String']['output'];
  appMetadata: AppMetadata;
  getWhoAmI: Wai;
  lastAccessLog?: Maybe<AccessLog>;
  listBookings: Array<Booking>;
  listClassSessions: Array<ClassSession>;
  profile: Profile;
  profiles: Profiles;
  stripe_invoice: Stripe_Invoice;
  stripe_invoices: Stripe_Invoices;
  stripe_subscription: Stripe_Subscription;
  stripe_subscriptions: Stripe_Subscriptions;
  timetable: Array<Timetable>;
  /** @deprecated Use getWhoAmI and WAI type instead */
  whoAmI: WhoAmI;
};


export type QueryListClassSessionsArgs = {
  date: Scalars['String']['input'];
};


export type QueryProfilesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProfilesFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStripe_InvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStripe_InvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Stripe_InvoicesFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStripe_SubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStripe_SubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTimetableArgs = {
  date: Scalars['String']['input'];
};

export type Room = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringFilterInput = {
  eq?: InputMaybe<Scalars['String']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
};

export type StripeCustomer = {
  balance: Scalars['Int']['output'];
  defaultCardId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type Stripe_Invoice = {
  amountDue: Scalars['Int']['output'];
  amountPaid: Scalars['Int']['output'];
  amountRemaining: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  hostedInvoiceUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Stripe_InvoiceStatus>;
  total: Scalars['Int']['output'];
};

export type Stripe_InvoiceEdge = {
  cursor: Scalars['String']['output'];
  node: Stripe_Invoice;
};

export enum Stripe_InvoiceStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paid = 'PAID',
  Uncollectible = 'UNCOLLECTIBLE',
  Void = 'VOID'
}

export type Stripe_Invoices = {
  edges: Array<Stripe_InvoiceEdge>;
  pageInfo: PageInfo;
};

export type Stripe_InvoicesFilter = {
  status?: InputMaybe<Stripe_InvoiceStatus>;
};

export type Stripe_PaymentIntent = {
  clientSecret?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  metadata: Scalars['JSON']['output'];
  status: Stripe_PaymentIntentStatus;
};

export enum Stripe_PaymentIntentStatus {
  Canceled = 'CANCELED',
  Processing = 'PROCESSING',
  RequiresAction = 'REQUIRES_ACTION',
  RequiresCapture = 'REQUIRES_CAPTURE',
  RequiresConfirmation = 'REQUIRES_CONFIRMATION',
  RequiresPaymentMethod = 'REQUIRES_PAYMENT_METHOD',
  Succeeded = 'SUCCEEDED'
}

export type Stripe_PaymentMethod = {
  card?: Maybe<Stripe_PaymentMethodCard>;
  id: Scalars['ID']['output'];
  link?: Maybe<Stripe_PaymentMethodLink>;
  sepaDebit?: Maybe<Stripe_PaymentMethodSepaDebit>;
  type: Stripe_PaymentMethodType;
};

export type Stripe_PaymentMethodCard = {
  brand: Stripe_PaymentMethodCardBrand;
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  last4: Scalars['String']['output'];
  wallet?: Maybe<Stripe_PaymentMethodCardWallet>;
};

export enum Stripe_PaymentMethodCardBrand {
  Amex = 'AMEX',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  EftposAu = 'EFTPOS_AU',
  Jcb = 'JCB',
  Mastercard = 'MASTERCARD',
  Unknown = 'UNKNOWN',
  Visa = 'VISA'
}

export enum Stripe_PaymentMethodCardWallet {
  ApplePay = 'APPLE_PAY',
  GooglePay = 'GOOGLE_PAY',
  Link = 'LINK'
}

export type Stripe_PaymentMethodLink = {
  email?: Maybe<Scalars['String']['output']>;
};

export type Stripe_PaymentMethodSepaDebit = {
  bankCode?: Maybe<Scalars['String']['output']>;
  branchCode?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
};

export enum Stripe_PaymentMethodType {
  Card = 'CARD',
  Link = 'LINK',
  SepaDebit = 'SEPA_DEBIT'
}

export type Stripe_PaymentMethods = {
  edges: Array<Stripe_PaymentMethodsEdge>;
  pageInfo: Stripe_PaymentMethodsPageInfo;
};

export type Stripe_PaymentMethodsEdge = {
  cursor?: Maybe<Scalars['String']['output']>;
  node: Stripe_PaymentMethod;
};

export type Stripe_PaymentMethodsPageInfo = {
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type Stripe_Price = {
  id: Scalars['ID']['output'];
  metadata: Scalars['JSON']['output'];
  product: Stripe_Product;
  productId: Scalars['ID']['output'];
  recurring?: Maybe<Stripe_PriceRecurring>;
  unitAmount?: Maybe<Scalars['Int']['output']>;
};

export enum Stripe_PriceInterval {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type Stripe_PriceRecurring = {
  interval: Stripe_PriceInterval;
  intervalCount: Scalars['Int']['output'];
};

export type Stripe_Product = {
  id: Scalars['ID']['output'];
  metadata: Scalars['JSON']['output'];
  name: Scalars['String']['output'];
};

export type Stripe_Subscription = {
  cancelAt?: Maybe<Scalars['DateTime']['output']>;
  cancelAtPeriodEnd: Scalars['Boolean']['output'];
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  currentPeriodEnd: Scalars['DateTime']['output'];
  currentPeriodStart: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  items: Array<Stripe_SubscriptionItem>;
  metadata: Scalars['JSON']['output'];
  status: Stripe_SubscriptionStatus;
  upcomingInvoice?: Maybe<Stripe_UpcomingInvoice>;
};

export type Stripe_SubscriptionEdge = {
  cursor: Scalars['String']['output'];
  node: Stripe_Subscription;
};

export type Stripe_SubscriptionItem = {
  id: Scalars['ID']['output'];
  metadata: Scalars['JSON']['output'];
  price: Stripe_Price;
  priceId: Scalars['String']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
};

export enum Stripe_SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  PastDue = 'PAST_DUE',
  Paused = 'PAUSED',
  Trialing = 'TRIALING',
  Unpaid = 'UNPAID'
}

export type Stripe_Subscriptions = {
  edges: Array<Stripe_SubscriptionEdge>;
  pageInfo: PageInfo;
};

export type Stripe_UpcomingInvoice = {
  amountDue: Scalars['Int']['output'];
  amountPaid: Scalars['Int']['output'];
  amountRemaining: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  total: Scalars['Int']['output'];
};

export type Tenant = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Timetable = {
  activity: Activity;
  activityId: Scalars['ID']['output'];
  endDateTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  room: Room;
  roomId: Scalars['ID']['output'];
  startDateTime: Scalars['DateTime']['output'];
  status: TimetableStatus;
};

export enum TimetableStatus {
  Available = 'AVAILABLE',
  Disabled = 'DISABLED',
  Full = 'FULL',
  Waitlist = 'WAITLIST'
}

export type Wai = {
  profileFirstName: Scalars['String']['output'];
  profileId: Scalars['ID']['output'];
  profileLastName: Scalars['String']['output'];
  tenantId: Scalars['ID']['output'];
  tenantName: Scalars['String']['output'];
  tenantTimezone: Scalars['String']['output'];
  userEmail: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type WhoAmI = {
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  profile?: Maybe<Profile>;
  profileId?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Scalars['String']['output']>>;
  tenant?: Maybe<Tenant>;
  tenantId?: Maybe<Scalars['String']['output']>;
};

export type ProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfilesQuery = { profiles: { edges: Array<{ cursor: string, node: { id: string, firstName: string, lastName: string, avatarUrl?: string | undefined, tenantId: string } }>, pageInfo: { hasNextPage: boolean, endCursor?: string | undefined } } };

export type ProfileLoginMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProfileLoginMutation = { token: string };

export type CreateProfileMutationVariables = Exact<{
  input: CreateProfileInput;
}>;


export type CreateProfileMutation = { profile: { id: string } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { token: string };

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = { whoAmI: { id: string, profile?: { id: string, firstName: string, lastName: string, avatarUrl?: string | undefined } | undefined } };

export type ProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileQuery = { profile: { id: string, firstName: string, lastName: string, avatarUrl?: string | undefined } };

export type AccessCredentialQueryVariables = Exact<{ [key: string]: never; }>;


export type AccessCredentialQuery = { accessCredential: string };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { resetPassword: boolean };

export type SubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionsQuery = { subscriptions: { edges: Array<{ node: { id: string, status: Stripe_SubscriptionStatus, cancelAt?: any | undefined, canceledAt?: any | undefined, cancelAtPeriodEnd: boolean, currentPeriodStart: any, currentPeriodEnd: any, items: Array<{ id: string, price: { id: string, unitAmount?: number | undefined, metadata: any, recurring?: { interval: Stripe_PriceInterval, intervalCount: number } | undefined, product: { id: string, name: string, metadata: any } } }>, upcomingInvoice?: { createdAt: any, amountRemaining: number } | undefined } }> } };

export type InvoicesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type InvoicesQuery = { invoices: { edges: Array<{ cursor: string, node: { id: string, createdAt: any, status?: Stripe_InvoiceStatus | undefined, hostedInvoiceUrl?: string | undefined, amountDue: number } }>, pageInfo: { hasNextPage: boolean, endCursor?: string | undefined } } };

export type InvoicesGuardQueryVariables = Exact<{ [key: string]: never; }>;


export type InvoicesGuardQuery = { draftInvoices: { edges: Array<{ node: { id: string } }> }, openInvoices: { edges: Array<{ node: { id: string, hostedInvoiceUrl?: string | undefined, amountRemaining: number } }> } };

export type CancelSubscriptionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CancelSubscriptionMutation = { subscription: { id: string } };

export type ReactivateSubscriptionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ReactivateSubscriptionMutation = { subscription: { id: string } };


export const ProfilesDocument = gql`
    query Profiles {
  profiles {
    edges {
      cursor
      node {
        id
        firstName
        lastName
        avatarUrl
        tenantId
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useProfilesQuery__
 *
 * To run a query within a React component, call `useProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfilesQuery(baseOptions?: Apollo.QueryHookOptions<ProfilesQuery, ProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfilesQuery, ProfilesQueryVariables>(ProfilesDocument, options);
      }
export function useProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilesQuery, ProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfilesQuery, ProfilesQueryVariables>(ProfilesDocument, options);
        }
export function useProfilesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProfilesQuery, ProfilesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProfilesQuery, ProfilesQueryVariables>(ProfilesDocument, options);
        }
export type ProfilesQueryHookResult = ReturnType<typeof useProfilesQuery>;
export type ProfilesLazyQueryHookResult = ReturnType<typeof useProfilesLazyQuery>;
export type ProfilesSuspenseQueryHookResult = ReturnType<typeof useProfilesSuspenseQuery>;
export type ProfilesQueryResult = Apollo.QueryResult<ProfilesQuery, ProfilesQueryVariables>;
export const ProfileLoginDocument = gql`
    mutation ProfileLogin($id: ID!) {
  token: profileLogin(id: $id)
}
    `;
export type ProfileLoginMutationFn = Apollo.MutationFunction<ProfileLoginMutation, ProfileLoginMutationVariables>;

/**
 * __useProfileLoginMutation__
 *
 * To run a mutation, you first call `useProfileLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileLoginMutation, { data, loading, error }] = useProfileLoginMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfileLoginMutation(baseOptions?: Apollo.MutationHookOptions<ProfileLoginMutation, ProfileLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProfileLoginMutation, ProfileLoginMutationVariables>(ProfileLoginDocument, options);
      }
export type ProfileLoginMutationHookResult = ReturnType<typeof useProfileLoginMutation>;
export type ProfileLoginMutationResult = Apollo.MutationResult<ProfileLoginMutation>;
export type ProfileLoginMutationOptions = Apollo.BaseMutationOptions<ProfileLoginMutation, ProfileLoginMutationVariables>;
export const CreateProfileDocument = gql`
    mutation CreateProfile($input: CreateProfileInput!) {
  profile: createProfile(input: $input) {
    id
  }
}
    `;
export type CreateProfileMutationFn = Apollo.MutationFunction<CreateProfileMutation, CreateProfileMutationVariables>;

/**
 * __useCreateProfileMutation__
 *
 * To run a mutation, you first call `useCreateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileMutation, { data, loading, error }] = useCreateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateProfileMutation, CreateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProfileMutation, CreateProfileMutationVariables>(CreateProfileDocument, options);
      }
export type CreateProfileMutationHookResult = ReturnType<typeof useCreateProfileMutation>;
export type CreateProfileMutationResult = Apollo.MutationResult<CreateProfileMutation>;
export type CreateProfileMutationOptions = Apollo.BaseMutationOptions<CreateProfileMutation, CreateProfileMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  token: createUser(input: $input)
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const WhoAmIDocument = gql`
    query WhoAmI {
  whoAmI {
    id
    profile {
      id
      firstName
      lastName
      avatarUrl
    }
  }
}
    `;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(baseOptions?: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
      }
export function useWhoAmILazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export function useWhoAmISuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmISuspenseQueryHookResult = ReturnType<typeof useWhoAmISuspenseQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<WhoAmIQuery, WhoAmIQueryVariables>;
export const ProfileDocument = gql`
    query Profile {
  profile {
    id
    firstName
    lastName
    avatarUrl
  }
}
    `;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
      }
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
        }
export function useProfileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileSuspenseQueryHookResult = ReturnType<typeof useProfileSuspenseQuery>;
export type ProfileQueryResult = Apollo.QueryResult<ProfileQuery, ProfileQueryVariables>;
export const AccessCredentialDocument = gql`
    query AccessCredential {
  accessCredential
}
    `;

/**
 * __useAccessCredentialQuery__
 *
 * To run a query within a React component, call `useAccessCredentialQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessCredentialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessCredentialQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccessCredentialQuery(baseOptions?: Apollo.QueryHookOptions<AccessCredentialQuery, AccessCredentialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccessCredentialQuery, AccessCredentialQueryVariables>(AccessCredentialDocument, options);
      }
export function useAccessCredentialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccessCredentialQuery, AccessCredentialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccessCredentialQuery, AccessCredentialQueryVariables>(AccessCredentialDocument, options);
        }
export function useAccessCredentialSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccessCredentialQuery, AccessCredentialQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccessCredentialQuery, AccessCredentialQueryVariables>(AccessCredentialDocument, options);
        }
export type AccessCredentialQueryHookResult = ReturnType<typeof useAccessCredentialQuery>;
export type AccessCredentialLazyQueryHookResult = ReturnType<typeof useAccessCredentialLazyQuery>;
export type AccessCredentialSuspenseQueryHookResult = ReturnType<typeof useAccessCredentialSuspenseQuery>;
export type AccessCredentialQueryResult = Apollo.QueryResult<AccessCredentialQuery, AccessCredentialQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($email: String!) {
  resetPassword(email: $email)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SubscriptionsDocument = gql`
    query Subscriptions {
  subscriptions: stripe_subscriptions {
    edges {
      node {
        id
        status
        cancelAt
        canceledAt
        cancelAtPeriodEnd
        currentPeriodStart
        currentPeriodEnd
        items {
          id
          price {
            id
            unitAmount
            recurring {
              interval
              intervalCount
            }
            product {
              id
              name
              metadata
            }
            metadata
          }
        }
        upcomingInvoice {
          createdAt
          amountRemaining
        }
      }
    }
  }
}
    `;

/**
 * __useSubscriptionsQuery__
 *
 * To run a query within a React component, call `useSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
      }
export function useSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
        }
export function useSubscriptionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
        }
export type SubscriptionsQueryHookResult = ReturnType<typeof useSubscriptionsQuery>;
export type SubscriptionsLazyQueryHookResult = ReturnType<typeof useSubscriptionsLazyQuery>;
export type SubscriptionsSuspenseQueryHookResult = ReturnType<typeof useSubscriptionsSuspenseQuery>;
export type SubscriptionsQueryResult = Apollo.QueryResult<SubscriptionsQuery, SubscriptionsQueryVariables>;
export const InvoicesDocument = gql`
    query Invoices($after: String) {
  invoices: stripe_invoices(first: 5, after: $after, filter: {status: PAID}) {
    edges {
      cursor
      node {
        id
        createdAt
        status
        hostedInvoiceUrl
        amountDue
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      after: // value for 'after'
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export function useInvoicesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesSuspenseQueryHookResult = ReturnType<typeof useInvoicesSuspenseQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const InvoicesGuardDocument = gql`
    query InvoicesGuard {
  draftInvoices: stripe_invoices(filter: {status: DRAFT}) {
    edges {
      node {
        id
      }
    }
  }
  openInvoices: stripe_invoices(filter: {status: OPEN}) {
    edges {
      node {
        id
        hostedInvoiceUrl
        amountRemaining
      }
    }
  }
}
    `;

/**
 * __useInvoicesGuardQuery__
 *
 * To run a query within a React component, call `useInvoicesGuardQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesGuardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesGuardQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoicesGuardQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesGuardQuery, InvoicesGuardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesGuardQuery, InvoicesGuardQueryVariables>(InvoicesGuardDocument, options);
      }
export function useInvoicesGuardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesGuardQuery, InvoicesGuardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesGuardQuery, InvoicesGuardQueryVariables>(InvoicesGuardDocument, options);
        }
export function useInvoicesGuardSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InvoicesGuardQuery, InvoicesGuardQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvoicesGuardQuery, InvoicesGuardQueryVariables>(InvoicesGuardDocument, options);
        }
export type InvoicesGuardQueryHookResult = ReturnType<typeof useInvoicesGuardQuery>;
export type InvoicesGuardLazyQueryHookResult = ReturnType<typeof useInvoicesGuardLazyQuery>;
export type InvoicesGuardSuspenseQueryHookResult = ReturnType<typeof useInvoicesGuardSuspenseQuery>;
export type InvoicesGuardQueryResult = Apollo.QueryResult<InvoicesGuardQuery, InvoicesGuardQueryVariables>;
export const CancelSubscriptionDocument = gql`
    mutation CancelSubscription($id: ID!) {
  subscription: stripe_cancelSubscription(id: $id) {
    id
  }
}
    `;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const ReactivateSubscriptionDocument = gql`
    mutation ReactivateSubscription($id: ID!) {
  subscription: stripe_reactivateSubscription(id: $id) {
    id
  }
}
    `;
export type ReactivateSubscriptionMutationFn = Apollo.MutationFunction<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>;

/**
 * __useReactivateSubscriptionMutation__
 *
 * To run a mutation, you first call `useReactivateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateSubscriptionMutation, { data, loading, error }] = useReactivateSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReactivateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>(ReactivateSubscriptionDocument, options);
      }
export type ReactivateSubscriptionMutationHookResult = ReturnType<typeof useReactivateSubscriptionMutation>;
export type ReactivateSubscriptionMutationResult = Apollo.MutationResult<ReactivateSubscriptionMutation>;
export type ReactivateSubscriptionMutationOptions = Apollo.BaseMutationOptions<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>;